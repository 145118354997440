<template>
  <div>
    <div>
      <div class="w3-col w3-left-align">
        <content-header v-bind:title="$t('chooseDesk', {siteName: currentSiteName})"></content-header>
      </div>
    </div>


    <div class="w3-container w3-margin-bottom zero">
      <loading v-bind:dspin="!contentLoaded"></loading>
    </div>

    <div class="flow-card blue-top w3-padding qpp-margin-sides-5pc w-85pc" v-show="contentLoaded">
      <table class="w3-table w3-bordered flow-table">
        <thead class="">
        <th>{{ $t("desk") }}</th>
        <th>{{ $t("waitingRoom") }}</th>
        <th>{{ $t("user") }}</th>
        <th class="qpp-text-align-center">{{ $t("linkMe") }}</th>
        </thead>

        <tbody v-show="desks.length === 0">
        <tr>
          <td class="w3-center flow-blue-color">
            <b class="font-size-1pt25em">{{ $t("nodesks") }}</b>
            {{ desks }}
          </td>
        </tr>
        </tbody>

        <tbody v-show="desks.length !== 0">
        <tr v-for="desk in desks"
            v-bind:id="desk.deskId" :key="desk.deskId">
          <td>
            <router-link class="flow-green-active-color font-weight-bold" v-if="masterCopy[desk.deskId].userId === currentUserId"
                         :to="{name: 'visit', params: {deskId: desk.deskId, userId: currentUserId}}">{{ desk.deskName }}
            </router-link>
            <span v-else>  {{ desk.deskName }}</span>
          </td>
          <td class="w-25pc">
            <flow-select :callback="setSelectedWR" :items="wRooms"
                         :mainItem="desk"
                         :preSelectedItem="preSelectedItem(desk.waitingRoomId)"
                         :valueKey="'name'"
                         :valueKeyIndex="'id'"
                         class="w3-block w3-border w3-round-medium"
                         type="text">
            </flow-select>
          </td>
          <td class="qpp-text-align-start qpp-align-buttons">
            <span class="w3-tooltip">
            <span> {{ desk.firstName }}  {{ desk.lastName }} </span>
            <span class="w3-text w3-tag standard-text-hover"
                  v-if="desk.userName && currentUserRole && currentUserRole==='ADMIN'">
                {{desk.userName}} </span>
            </span>
          </td>
          <td class="qpp-text-align-center">
            <span class="btn flow-btn w3-padding-small w3-round-medium flow-green-bg-active-color"
                  v-bind:class="!currentUserLinked ? 'cursor-pointer' : 'w3-disabled'"
                  @click.prevent="linkCurrentUser(desk)">
              <i class="far fa-user w3-large imp-white"></i>
            </span>
          </td>
          <td class="qpp-inline-buttons qpp-desks">
            <span class="btn flow-btn w3-padding-small w3-round-medium"
                  v-bind:class="showSaveButton(desk) ? 'cursor-pointer' : 'w3-disabled'"
                  @click.prevent="confirmUpdate(desk)">
                  <i class="fa fa-link w3-large flow-green-active-color"></i>
                  <span
                      class="flow-green-active-color flow-margin-left font-weight-bold w3-large">{{
                      $t('confirm')
                    }}
                  </span>
                </span>

            <span>
                  <span
                      class="w3-margin-left cursor-pointer btn flow-btn w3-padding-small w3-round-medium"
                      v-bind:class="masterCopy[desk.deskId].userId===null ? 'w3-disabled' : 'cursor-pointer'"
                      @click="unlinkDesk(desk)">
                      <i class="fa fa-unlink w3-text-deep-orange"></i>
                      <span
                          class="w3-text-deep-orange flow-margin-left font-weight-bold w3-large">{{
                          $t('unlink')
                        }}</span>
                  </span>
                </span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import ContentHeader from "../../common/ContentHeader";
import Loading from "../../common/Loading";
import FlowSelect from "../../common/FlowSelect";

export default {
  name: "ConnectDesk",
  components: {ContentHeader, Loading, FlowSelect},
  data() {
    return {
      contentLoaded: false,
      desks: [],
      masterCopy: [],
      wRooms: [],
      currentUserId: null,
      currentUserFname: null,
      currentUserLname: null,
      currentUserRole: null,
      currentUserLinked: false,
      currentSiteName: null,
      refreshInterval:30000,
      timeOutId: undefined
    }
  },
  computed: {},
  mounted() {
    this.getDesksList();
    this.getWRooms();
    this.currentUserId = this.$store.getters["users/getCurrentUser"].id
    this.currentSiteName = this.$store.getters["sites/getCurrentSite"].name
    this.getRefreshInterval();
    this.refresh();
    this.getCurrentUser();
  },
  beforeUnmount() {
    clearTimeout(this.timeOutId);
  },
  methods: {
    refresh() {
      const app = this;
      app.getDesksList().then(() => {
        app.timeOutId = setTimeout(app.refresh, app.refreshInterval);
      }).catch(() => {
        app.timeOutId = setTimeout(app.refresh, app.refreshInterval);
      })
    },
    getRefreshInterval(){
      const app = this;
      app.$restClient.configuration.getDeskRefreshProperty().then(response => {
        let interval = response.data;
        if(!isNaN(interval)&& interval>=10000){
          app.refreshInterval = interval;
        }
      })
    },
    preSelectedItem(_wrId) {
      var wr = null;
      for (let i = 0; i < this.wRooms.length; i++) {
        if (this.wRooms[i].id === _wrId) {
          wr = this.wRooms[i]
          break;
        }
      }
      return wr;
    },

    getCurrentUser() {
      const app = this;
      app.$restClient.qppUsers.getUser(app.currentUserId).then(response => {
        app.currentUserFname = response.data.firstName
        app.currentUserLname = response.data.lastName
        app.currentUserRole = response.data.role;
      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title: this.$t("failed")});
      })
    },

    getWRooms() {
      const app = this;
      app.$restClient.waitingRooms.all().then(response => {
        app.wRooms = response.data;
        this.$helper.sortByKey(app.wRooms, "name");
        app.wRooms.unshift({
          id: null,
          name: null
        });
      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title: this.$t("failed")});
      })
    },

    getDesksList() {
      this.currentUserLinked = false;
      const app = this;
      // app.desks = [];
      return app.$restClient.deskDetails.all().then(response => {
        app.contentLoaded = true;
        let data = response.data;
        if (data.length > 0) {
          app.desks = data;
          data.forEach(function (d) {
            app.manageMasterCopy(d);
          });
        }

      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title: this.$t("failed")});
      });
    },

    manageMasterCopy(_desk) {
      this.masterCopy[_desk.deskId] = {
        waitingRoomName: _desk.waitingRoomName,
        userId: _desk.userId
      };

      if (this.currentUserId === _desk.userId) {
        this.currentUserLinked = true;
      }
    },

    showSaveButton(_desk) {
      if (!_desk) {
        return false;
      }
      let mp = this.masterCopy[_desk.deskId];
      if (!mp) {
        return false;
      }

      return (mp.waitingRoomName !== _desk.waitingRoomName || mp.userId !== _desk.userId);
    },
    setSelectedWR(item, _desk) {
      _desk.waitingRoomName = item.name
      _desk.waitingRoomId = item.id
    },

    unlinkDesk(_desk) {
      if (this.masterCopy[_desk.deskId].userId === null) return;
      let app = this;
      app.$restClient.deskDetails.unlinkDesk(_desk.deskId, this.currentUserId).then(() => {
        app.getDesksList();
        app.$notify.notifySuccess({title: this.$t("success"), message: this.$t("modified-success")});
      }).catch(err => {
        console.log(err);
          app.$notify.notifyError({title: this.$t("failed")});
      })},

    linkCurrentUser(_desk) {
      if (this.currentUserLinked) return;
      _desk.userId = this.currentUserId;
      _desk.firstName = this.currentUserFname;
      _desk.lastName = this.currentUserLname;
    },

    confirmUpdate(_desk,) {
      if (!this.showSaveButton(_desk)) return;
      this.updateDesk(_desk.deskId, _desk.waitingRoomId, _desk.userId)
    },

    updateDesk(deskId, waitingRoomId = null, userId = null) {
      let app = this;
      let payload = {
        createdBy: this.currentUserId,
      };

      if (waitingRoomId !== null) {
        payload.waitingRoomId = waitingRoomId;
      }

      if (userId !== null) {
        payload.userId = userId;
      }

      app.$restClient.deskDetails.update(deskId, payload).then(() => {
        app.getDesksList();
        app.$notify.notifySuccess({title: this.$t("success"), message: this.$t("modified-success")});
      }).catch(err => {
        if (err.response.status === 400) {
          app.$notify.notifyError({title: this.$t("deskAlreadyLinked")});
          app.getDesksList();
        } else {
          app.$notify.notifyError({title: this.$t("failed")});
        }
      })
    }
  }
}

</script>