    var successIconCls = "fa-check";
    var infoIconCls = "fa-info";
    var warnIconCls = "fa-exclamation";
    var errorIconCls = "fa-times";
    var notifications = undefined;

const Notify = {

    notifySuccess (params) {
        Notify.displayNewNotification(params, "success", successIconCls);
    },

    notifyInfo(params) {
        Notify.displayNewNotification(params, "info", infoIconCls);
    },

    notifyWarn(params) {
        Notify.displayNewNotification(params, "warn", warnIconCls);
    },

    notifyError(params) {
        Notify.displayNewNotification(params, "error", errorIconCls);
    },

    getNotifications() {
        if (!notifications) {
            notifications = document.getElementById("notifications");
        }

        return notifications;
    },

    displayNewNotification(params, alertCls, iconCls) {
        var newN = Notify.createNewNotification(params, alertCls, iconCls);
        var nParent = Notify.getNotifications();
        nParent.appendChild(newN);
        setTimeout(function(){nParent.removeChild(newN);}, 10000);
        Notify.arrangeNotifications();
    },

    createNewNotification(params, alertCls, iconCls) {
        var newN = document.createElement("div");
        newN.classList.add("notification");
        newN.classList.add(alertCls);

        var title = "";
        var message = "";
        if (params.title) {
            title = params.title;
        }
        if (params.message) {
            message = params.message;
        }

        newN.appendChild(Notify.createIcon(iconCls));
        newN.appendChild(Notify.createMessage(title, message));

        var close = document.createElement("i");
        close.classList.add("fa");
        close.classList.add("fa-times");
        close.classList.add("close");
        close.onclick = function(event) {
            Notify.getNotifications().removeChild(event.target.parentElement);
            Notify.arrangeNotifications();
        };

        newN.appendChild(close);


        var msgC = document.createElement("div");
        msgC.classList.add("content");
        msgC.innerHTML = message;

        newN.appendChild(msgC);

        return newN;
    },

    //createMessage(title, message)
    createMessage(title) {
        var msgT = document.createElement("div");
        msgT.classList.add("title");
        msgT.innerHTML = title;

        var msg = document.createElement("div");
        msg.classList.add("message");
        msg.appendChild(msgT);

        return msg;
    },

    createIcon(cls) {
        var ic = document.createElement("i");
        ic.classList.add("fa");
        ic.classList.add("fa-lg");
        ic.classList.add("w3-animate-zoom");
        ic.classList.add(cls);

        var dic = document.createElement("div");
        dic.classList.add("icon");
        dic.appendChild(ic);

        return dic;
    },

    arrangeNotifications() {
        var notifications = document.getElementsByClassName("notification");
        var bottom = Notify.getTop();
        for (var i = notifications.length - 1; i >= 0; i--) {
            var notification = notifications[i];
            notification.style.bottom = bottom + "px";
            bottom += notification.offsetHeight + 5;
            notification.style.visibility = "visible";
        }
    },

    getTop() {
        var fc = document.getElementById("flow-content");
        if (fc) {
            return fc.offsetTop;
        } else {
            return 40;
        }
    }

};

export default {
    install: (app) => {
        app.config.globalProperties.$notify = Notify
    }
}