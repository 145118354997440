class Messages {

    constructor(httpMethods) {
        this.api = httpMethods;
        this.EP = {
            GET_ALL_MESSAGES: '/api/sites/:siteId/messages/all',
            ADD_MESSAGE: '/api/sites/:siteId/messages',
            GET_DASHBOARD_MESSAGES: '/api/sites/:siteId/messages/dashboard',
            GET_UPDATE_DELETE_MESSAGE: '/api/sites/:siteId/messages/:id'
        };
    }

    all() {
        return this.api.get(this.EP.GET_ALL_MESSAGES);
    }

    dashboardMessages() {
        return this.api.get(this.EP.GET_DASHBOARD_MESSAGES);
    }

    get(messageId) {
        return this.api.get(
            this.EP.GET_UPDATE_DELETE_MESSAGE,
            {
                id: messageId
            }
        );
    }

    add(data) {
        return this.api.post(
            this.EP.ADD_MESSAGE,
            {
                publicationDate: data.publicationDate,
                messageImportance: data.messageImportance,
                title: data.title,
                description: data.description,
                comment: data.comment,
                expiryDate: data.expiryDate
            }
        );
    }

    update(data) {
        return this.api.put(
            this.EP.GET_UPDATE_DELETE_MESSAGE,
            {
                publicationDate: data.publicationDate,
                messageImportance: data.messageImportance,
                title: data.title,
                description: data.description,
                comment: data.comment,
                expiryDate: data.expiryDate
            }, {
                id: data.id
            }
        );
    }

    delete(id) {
        return this.api.del(
            this.EP.GET_UPDATE_DELETE_MESSAGE,
            {
                id: id
            }
        );
    }


}

export default Messages;