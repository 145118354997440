<template>
  <div>
    <div class="w3-container w3-margin-bottom zero">
      <loading v-bind:dspin="!contentLoaded"></loading>
    </div>

    <div class=" blue-top w3-padding qpp-margin-sides-5pc w-85pc" v-show="contentLoaded">
      <table  class="w3-table w3-bordered flow-table">
        <thead class="">
        <th>{{ $t("type") }}</th>
        <th>{{ $t("text") }}</th>
        <th></th>
        </thead>

        <tbody v-show="texts.length === 0">
        <tr>
          <td class="w3-center flow-blue-color" colspan="8">
            <b class="font-size-1pt25em">{{ $t("noStandardTexts") }}</b>
            {{ texts }}
          </td>
        </tr>
        </tbody>

        <tbody v-show="texts.length !== 0" class="cursor-pointer">
        <tr v-for="text in texts"
            v-bind:id="text.key" :key="text.key"
            @click="showEditForm(text)">
          <td>
            <span>  {{ $t(text.type) }}</span>
          </td>
          <td>
            <span class="w3-tooltip"> {{ truncateMessage(text.value) }}
              <span class="w3-text w3-tag standard-text-hover" v-if="text.value.length > 95">
                {{text.value}} </span>
            </span>
          </td>
          <td>
            <span class="w3-margin-left cursor-pointer btn flow-btn w3-padding-small w3-round-medium"
                 @click.stop.prevent="deleteText(text)" v-if="!openEditForm && !openNewForm">
              <i class="fa fa-trash w3-text-deep-orange"></i>
              <span class="w3-text-deep-orange flow-margin-left font-weight-bold w3-large flow-no-wrap">
                {{$t('delete')}}
              </span>
            </span>
          </td>

        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import Loading from "@/components/common/Loading";

export default {
  name: 'StandardTextList',
  components: {Loading},
  props: ['stTextListUpdated','openEditForm','openNewForm'],
  emits: ['openStTextDetails'],

  data() {
    return {
      contentLoaded: false,
      texts: []
    }
  },

  mounted() {
    this.getAllStandardTexts();
  },

  watch: {
    stTextListUpdated() {
      if(this.stTextListUpdated === true)
          this.getAllStandardTexts();
    }
  },

  methods: {
    getAllStandardTexts() {
      const app = this;
      app.texts = [];
      app.$restClient.languages.allLocalesForDefaultLanguage().then(response => {
        app.contentLoaded = true;
        let data = response.data;
        if (data.length > 0) {
          app.texts = data;
        }
      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title: this.$t("failed")});
      })
    },

    showEditForm(text) {
      this.$emit('openStTextDetails', text);
    },

    deleteText(text) {
      const app = this;
      app.$restClient.languages.deleteStandardTexts({
        "languageId": text.languageId,
        "key": text.key
      }).then(response => {
        app.contentLoaded = true;
        let data = response.data;
        if (data.length > 0) {
          app.texts = data;
        }
        this.getAllStandardTexts();
      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title: this.$t("failed"), message: this.$t("standard-text-in-use")});
      })
    },

    truncateMessage(message) {
      if(message.length > 95)
        return message.substring(0, 95)+"(...)";
      return message;
    }

  }
}
</script>