<template>
  <div>
    <div>
      <div class="w3-col w3-left-align">
        <content-header v-bind:title="$t('walking-instructions')"></content-header>
        <div class="w3-right w3-margin-right flow-margin-top">
          <i class="fa fa-plus flow-blue-color w3-xlarge cursor-pointer w3-margin-right" @click="openNewForm = true"></i>
        </div>
      </div>
    </div>

    <div class="content-sidebars sidebars-70-30 ">
      <div v-if="openNewForm" class="w3-container content-sidebar-right">
        <i class="fa fa-times w3-left cursor-pointer w3-xlarge w3-text-gray flow-margin-top flow-margin-right-max" @click="openNewForm = false"></i>
        <new-walking-instruction :onClose="() => {openNewForm = false}" @newInstructionAdded="updateInstructionsList($event)"
                                 :domains="domains" :waitingRooms="waitingRooms" :kiosks="kiosks"
                                  :regimes="regimes" :standardTexts="standardTexts"></new-walking-instruction>
      </div>

      <div class="content-sidebars sidebars-70-30 ">
        <div v-if="openEditForm" class="w3-container content-sidebar-right">
          <i class="fa fa-times w3-left cursor-pointer w3-xlarge w3-text-gray flow-margin-top flow-margin-right-max" @click="openEditForm = false"></i>
          <edit-walking-instruction :onClose="() => {openEditForm = false}" @instructionUpdated="updateInstructionsList($event)"
                                    @refresh-instructions-list="getAllDetails"
                                   :domains="domains" :waitingRooms="waitingRooms" :kiosks="kiosks"
                                   :regimes="regimes" :standardTexts="standardTexts"
                                    :instruction="currentInstruction"></edit-walking-instruction>
        </div>
      </div>
      <div v-bind:class="openNewForm || openEditForm? 'content-sidebar-left' : ''">
        <walking-instruction-list :newInstructionAdded="newInstructionAdded" :openNewForm="openNewForm"
                                  :contentLoaded="contentLoaded"
                                  :instructions = "instructions"
                                  :openEditForm="openEditForm"
                                  @openDetails="openEditDetailsForm"
        ></walking-instruction-list>
      </div>
    </div>

  </div></template>

<script>
import ContentHeader from "../../common/ContentHeader";
import WalkingInstructionList from "./WalkingInstructionList";
import axios from 'axios';
import NewWalkingInstruction from "@/components/views/walking-instructions/NewWalkingInstruction";
import EditWalkingInstruction from "@/components/views/walking-instructions/EditWalkingInstruction";

export default {
  name: "WalkingInstruction",
  components: {NewWalkingInstruction,EditWalkingInstruction, WalkingInstructionList,  ContentHeader},
  data() {
    return {
      contentLoaded: false,
      instructions: [],
      openNewForm: false,
      openEditForm: false,
      newInstructionAdded: undefined,
      domains: [],
      kiosks: [],
      waitingRooms: [],
      regimes: [],
      standardTexts: [],
      currentInstruction: null
    }
  },
  mounted() {
    this.getAllDetails();

    axios.all([
          this.$restClient.domains.all(),
          this.$restClient.qppUsers.getKiosks(),
          this.$restClient.waitingRooms.all(),
          this.$restClient.regimes.all(),
          this.$restClient.languages.allLocalesForDefaultLanguage()
        ]
    ).then(axios.spread((domainsResponse,kiosksResponse, roomsResponse, regimesResponse, standardTextResponse) => {
        if(domainsResponse.status === 200 && kiosksResponse.status === 200 && roomsResponse.status === 200
        && standardTextResponse.status === 200 && regimesResponse.status === 200) {
          // adding empty values becuase dropdown should show empty values also.
          this.domains = [{id:null, name:null},...domainsResponse.data];
          this.kiosks = [{id:null, username:null},...kiosksResponse.data];
          this.waitingRooms = [{id:null, name:null, route: null},...roomsResponse.data];
          this.regimes = [{id:null, name:null},...regimesResponse.data];
          this.standardTexts = standardTextResponse.data;
        }
    }));

  },

  watch: {
    newInstructionAdded() {
      this.getAllDetails();
    }
  },

  methods: {
    getAllDetails() {
      const app = this;
      app.instructions = [];
      app.$restClient.walkingInstructions.all().then(response => {
        app.contentLoaded = true;
        let data = response.data;
        if (data.length > 0) {
          app.instructions = data;
        }
      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title: this.$t("failed")});
      })
    },

    updateInstructionsList (instruction) {
      this.newInstructionAdded = instruction;
    },

    openEditDetailsForm(instruction) {
      this.currentInstruction = instruction;
      this.openEditForm = true;
    }
  }
}
</script>