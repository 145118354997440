<template>
  <div>
    <div>
      <div class="w3-col w3-left-align">
        <content-header v-bind:title="$t('languages')"></content-header>
      </div>
    </div>


    <div class="content-sidebars sidebars-70-30 ">
      <div>
        <languages-list></languages-list>
      </div>
    </div>

  </div>
</template>
<script>
import ContentHeader from "../../common/ContentHeader";
import LanguagesList from "../languages/LanguagesList";

export default {
  name: "Languages",
  components: { LanguagesList, ContentHeader},
  data() {
    return {
    }
  },
  methods: {
  }
}

</script>