class Desks {

    constructor(httpMethods) {
        this.api = httpMethods;
        this.EP = {
            GET_ALL_DESKS: '/api/sites/:siteId/desks',
            ADD_DESK: '/api/sites/:siteId/desks',
            GET_UPDATE_DELETE_DESK: '/api/sites/:siteId/desks/:deskId'
        };
    }

    all() {
        return this.api.get(this.EP.GET_ALL_DESKS);
    }

    add(data) {
        return this.api.post(
            this.EP.ADD_DESK,
            {
                name: data.name,
                createdBy: 1, // TODO send login user ID here
                updatedBy: 1
            }
        );
    }

    update(data) {
        return this.api.put(
            this.EP.GET_UPDATE_DELETE_DESK,
            {
                id: data.id,
                name: data.name
            },
            {
                deskId: data.id,
                createdBy: 1, // TODO send login user ID here
                updatedBy: 1
            }
        );
    }

    get(deskId) {
        return this.api.get(
            this.EP.GET_UPDATE_DELETE_DESK,
            {
                deskId: deskId
            }
        );
    }

    delete(deskId) {
        return this.api.del(
            this.EP.GET_UPDATE_DELETE_DESK,
            {
                deskId: deskId
            }
        );
    }
}

export default Desks;