<template>
  <div>
    <div>
      <div class="w3-col w3-left-align">
        <content-header v-bind:title="$t('desks')"></content-header>
        <div class="w3-right w3-margin-right flow-margin-top">
          <i class="fa fa-plus flow-blue-color w3-xlarge cursor-pointer w3-margin-right" @click="openNewForm = true"></i>
        </div>
      </div>
    </div>


    <div class="content-sidebars sidebars-70-30 ">
      <div v-if="openNewForm" class="w3-container content-sidebar-right">
        <i class="fa fa-times w3-left cursor-pointer w3-xlarge w3-text-gray flow-margin-top flow-margin-right-max" @click="openNewForm = false"></i>
        <new-desk :onClose="() => {openNewForm = false}" @addedNewDesk="updateDeskList($event)"></new-desk>
      </div>

      <div v-bind:class="openNewForm ? 'content-sidebar-left' : ''">
        <desks-list :newDeskAdded="newDeskAdded"></desks-list>
      </div>
    </div>

  </div>
</template>
<script>
import ContentHeader from "../../common/ContentHeader";
import DesksList from "../desks/DesksList";
import NewDesk from "../desks/NewDesk";

export default {
  name: "Desks",
  components: {NewDesk, DesksList, ContentHeader},
  data() {
    return {
      openNewForm: false,
      newDeskAdded: false
    }
  },
  methods: {
    updateDeskList (desk) {
     this.newDeskAdded = desk;
    }
  }
}

</script>