<template>
  <div class="waiting-room-screen">
    <div v-if="!showNotification">
      <div class="images-block">
        <img :src="src" class="image"/>
      </div>
      <div v-if="users.length <= 5">
        <div v-for="user in users" class="names-block" :key="user">
          <div>{{user.name}}</div>
        </div>
      </div>
      <div v-else>
        <div v-for="user in currentFilteredUsers" class="names-block" :key="user">
          <div>{{user.name}}</div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="font-weight-bold div-ticket-number"> {{ ticketNumber }}</div>
      <div class="div-ticket-arrow"><i class="fa fa-arrow-down font-weight-bold "/></div>
      <div class="font-weight-bold div-details">{{deskName}}</div>
      <div class="font-weight-bold div-details">{{user}}</div>
<!--      <i class="fa fa-speaker-deck"><audio src="@/assets/notification.mp3" ></audio> </i>-->
    </div>
  </div>
</template>

<script>

import img1 from '@/assets/images/waitingroomscreen/1.jpg';
import img2 from '@/assets/images/waitingroomscreen/2.jpg';
import img3 from '@/assets/images/waitingroomscreen/3.jpg';
import img4 from '@/assets/images/waitingroomscreen/4.jpg';
import img5 from '@/assets/images/waitingroomscreen/5.jpg';
import sound from '@/assets/notification.mp3';
import Synapse from "@/assets/js/synapse";


export default {
  name: "WaitingRoomScreen",
  data() {
    return {
      socket: null,
      screenId: 0,
      audio: "",
      imagesPath: "",
      images: [img1, img2, img3, img4, img5],
      users: [],
      filteredUsers: [],
      currentFilteredUsers: [],
      src: img1,
      showNotification: false,
      ticketNumber: "",
      deskName: "",
      user: ""
    }
  },

  mounted() {
    this.screenId = this.$route.params["screenId"];
    this.imagesPath =  this.$WRImagesPath;
    // code to load images....
    this.socket = new Synapse("dev.api.qarts.eu", this.screenId);
    this.getUsersByRoom();
    setInterval(() => {
      this.getUsersByRoom();
    }, 60000);
    this.iterateImages();
    this.socket.onMessage(this.onNotification);
    this.audio = new Audio(sound);
  },

  methods: {
    iterateImages: function () {
      let i = 0;
      setInterval(() => {
        this.src = this.images[i];
        if (++i === this.images.length) i = 0;
      }, 10000);
    },

    iterateUsers: function () {
      let i = 0;
      setInterval(() => {
        this.currentFilteredUsers = this.filteredUsers[i];
        if (++i === this.filteredUsers.length) i = 0;
      }, 10000);
    },

    onNotification(message) {
      var _message = JSON.parse(message);
      this.ticketNumber = _message.ticketNumber;
      this.deskName = _message.deskName;
      this.user = _message.user;
      this.showNotification = true;
      this.audio.play();
      setTimeout(() => {
          this.showNotification = false;
          this.ticketNumber = "";
      }, 10000);
    },

    getUsersByRoom() {
      const app = this;
      app.users = [];
      app.$restClient.waitingRooms.usersByRoom(this.screenId).then(response => {
        this.users = response.data;
        if(this.users.length > 5) {
          for (let i = 0; i < this.users.length; i += 5) {
            const chunk = this.users.slice(i, i + 5);
            this.filteredUsers.push(chunk);
          }
          this.iterateUsers();
        }
      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title: this.$t("failed")});
      })
    }

  }
}
</script>