<template>
  <loading :dspin="true"></loading>
</template>

<script>
import Loading from "./common/Loading";

export default {
  name: "Logout",
  components: {Loading},
  mounted() {
    this.$restClient.authentication.logout()
  }
}
</script>

<style scoped>

</style>