class DomainTypes {

    constructor(httpMethods) {
        this.api = httpMethods;
        this.EP = {
            GET_ALL_DOMAIN_TYPES: '/api/domain-types',
            GET_CURRENT_USER_DOMAIN_TYPES: '/api/domain-types?currentUser=true'
            // ADD_DOMAIN: '/api/sites/:siteId/domains',
            // GET_UPDATE_DELETE_DOMAIN: '/api/sites/:siteId/domains/:domainId'
        };
    }

    all() {
        return this.api.get(this.EP.GET_ALL_DOMAIN_TYPES);
    }

    currentUserDT() {
        return this.api.get(this.EP.GET_CURRENT_USER_DOMAIN_TYPES);
    }

    // add(data) {
    //     return this.api.post(
    //         this.EP.ADD_DOMAIN,
    //         {
    //             name: data.name,
    //             createdBy: 1, // TODO send login user ID here
    //             updatedBy: 1
    //         }
    //     );
    // }
    //
    // update(data) {
    //     return this.api.put(
    //         this.EP.GET_UPDATE_DELETE_DOMAIN,
    //         {
    //             id: data.id,
    //             name: data.name
    //         },
    //         {
    //             domainId: data.id,
    //             createdBy: 1, // TODO send login user ID here
    //             updatedBy: 1
    //         }
    //     );
    // }
    //
    // get(domainId) {
    //     return this.api.get(
    //         this.EP.GET_UPDATE_DELETE_DOMAIN,
    //         {
    //             domainId: domainId
    //         }
    //     );
    // }
    //
    // delete(domainId) {
    //     return this.api.del(
    //         this.EP.GET_UPDATE_DELETE_DOMAIN,
    //         {
    //             domainId: domainId
    //         }
    //     );
    // }
}

export default DomainTypes;