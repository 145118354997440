class DeskDetails {

    constructor(httpMethods) {
        this.api = httpMethods;
        this.EP = {
            GET_ALL_DESKS: '/api/sites/:siteId/desks/details',
            LINK_DESK: '/api/sites/:siteId/desks/details/:deskId',
            UNLINK_DESK: '/api/sites/:siteId/desks/details/:deskId/unlink/:userId'
        };
    }

    all() {
        return this.api.get(this.EP.GET_ALL_DESKS);
    }

    update(deskId, data) {
        return this.api.put(
            this.EP.LINK_DESK, data, {deskId: deskId}
        );
    }

    unlinkDesk(deskId, userId) {
        return this.api.del(
            this.EP.UNLINK_DESK, {deskId: deskId, userId: userId }
        );
    }
}

export default DeskDetails;