<template>
  <div>
    <div>
      <div class="w3-col w3-left-align">
        <content-header v-bind:title="$t('domains')"></content-header>
        <div class="w3-right w3-margin-right flow-margin-top">
          <i class="fa fa-plus flow-blue-color w3-xlarge cursor-pointer w3-margin-right" @click="openNewForm = true"></i>
        </div>
      </div>
    </div>

    <div class="content-sidebars sidebars-60-40">
      <div v-if="openNewForm && !openDetailsForm" class="w3-container content-sidebar-right">
        <i class="fa fa-times w3-left cursor-pointer w3-xlarge w3-text-gray flow-margin-top flow-margin-right-max" @click="openNewForm = false"></i>
        <new-domain :onClose="() => {openNewForm = false}" :types=typesList  @addedNewDomain="closeAndUpdateDomainList($event)"
                    :standardTexts="standardTexts"></new-domain>
      </div>

      <div v-if="openDetailsForm && !openNewForm" class="w3-container content-sidebar-right">
       <i class="fa fa-times w3-left cursor-pointer w3-xlarge w3-text-gray flow-margin-top flow-margin-right-max" @click="openDetailsForm = false"></i>
        <domain-details :onClose="() => {openDetailsForm = false}"
                     @onCloseWindow = "() => {openDetailsForm = false}"
                     :types=typesList  @updatedDomain="closeAndUpdateDomainList($event)"
                     :currentDomain="domainToOpen"
                      :standardTexts="standardTexts"
        ></domain-details>
      </div>

      <div v-bind:class="openNewForm || openDetailsForm? 'content-sidebar-left' : ''">
        <domains-list :newDomainAdded="newDomainAdded" @openDomainDetails="openEditSideBar" :standardTextDictionary="standardTextDictionary"></domains-list>
      </div>
    </div>
  </div>

</template>

<script>
import ContentHeader from "../../common/ContentHeader";
import DomainsList from "../domains/DomainsList";
import NewDomain from "../domains/NewDomain";
import DomainDetails from "@/components/views/domains/DomainDetails";

export default {
  name: "Domains",
  components: {DomainDetails, NewDomain, DomainsList, ContentHeader},

  data() {
    return {
      openNewForm: false,
      openDetailsForm: false,
      newDomainAdded: false,
      domainToOpen : null,
      standardTexts: [],
      standardTextDictionary: {},
      typesList: []
    }
  },
  mounted() {
    this.getTypesList();
    this.getStandardTexts();
  },
  methods: {
    getStandardTexts(){
      const app = this;
      return this.$restClient.languages.allDomainLocalesForDefaultLanguage().then(response => {
        app.standardTexts = response.data;
        app.standardTextDictionary = {};
        app.standardTexts.forEach((standardText) => {
          app.standardTextDictionary[standardText.key] = standardText.value;
        })
        app.standardTexts.unshift({value:"",key:""})
      }).catch(error => {
        app.$notify.notifyError({title: app.$t("something-went-wrong")});
        console.log(error);
      })
    },

    getTypesList() {
      const app = this;
      app.$restClient.domainTypes.all().then(response => {
        let data = response.data;
        if (data.length > 0) {
          app.typesList = JSON.parse(JSON.stringify(data));
        }
      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title: this.$t("failed")});
      })
    },

    closeAndUpdateDomainList (domain) {
      this.newDomainAdded = domain;
      this.openDetailsForm = false;
    },

    openEditSideBar(_domain) {
      this.domainToOpen = _domain;
      this.openDetailsForm = true;
    }
  }
}

</script>