import nl from './languages/nl.json'
import {createI18n} from 'vue-i18n'

const locale = 'NL';
const messages = {
    NL: nl
}

const i18n = new createI18n({
    locale,
    messages
})

export default i18n
