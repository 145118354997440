<template>
  <div v-if="loaded" style="min-height: 152px">
    <div v-if="!ongoing.currentVisits || ongoing.currentVisits.length === 0">
      <h5 class="zero-bottom w3-animate-opacity w3-center w3-padding">
                <span class="flow-margin-left font-weight-bold w3-margin-top w3-text-deep-orange">
                    <i class="fa fa-bullhorn"></i>
                    {{ $t('notOngoing') }}
                </span>
      </h5>
    </div>

    <div v-else-if="ongoing && ongoing.currentVisits.length>1">
      <div>
        <h5 class="flow-blue-color zero-bottom w3-animate-opacity w3-center">
          <i class="fa fa-users flow-green-active-color" aria-hidden="true" :title="$t('group')" style="display: inline-block">
          </i>
          <span class="font-weight-bold">
            <p style="display: inline-block; margin-left: 10px">{{ $t("groupAppointment") + ": " + ongoing.currentVisits.length+" "+$t("persons")}}</p>
          </span><br>
          <span class="font-weight-normal">{{ongoing.groupName}}
          </span>
        </h5>
        <button class="flow-btn w3-btn w3-border w3-ripple w3-round-medium w3-large w3-margin-top w3-deep-orange"
                @click="callback">
          {{ $t('endVisit') }}
        </button>
      </div>
    </div>

    <div v-else-if="ongoing && ongoing.currentVisits" class="w3-container w3-center w3-margin-bottom">
      <div>
        <h5 class="flow-blue-color zero-bottom w3-animate-opacity w3-center">
          <span class="font-weight-bold">
            <VisitorNameHeader :props-visitor="ongoing.currentVisits[0].visitor"></VisitorNameHeader>
          </span><br>
          <span class="font-weight-normal">
            ({{ ongoing.currentVisits[0].appointmentDateTime }}<span v-if="ongoing.currentVisits[0].domainTypeName"> - {{ ongoing.currentVisits[0].domainTypeName }}</span>)
          </span><br>
          <i class="fa fa-birthday-cake flow-green-active-color" aria-hidden="true" :title="$t('dob')">
          </i>
          <span class="flow-margin-left">{{ ongoing.currentVisits[0].visitor.dob ? ongoing.currentVisits[0].visitor.dob : $t('notAvailable') }}</span>
        </h5>

        <button class="flow-btn w3-btn w3-border w3-ripple w3-round-medium w3-large w3-margin-top w3-deep-orange"
                @click="callback">
          {{ $t('endVisit') }}
        </button>
      </div>
    </div>
  </div>

  <div v-else class="w3-col w3-padding-large w3-center">
    <div>
      <h5 class="flow-blue-color zero-bottom w3-animate-opacity w3-center">
                        <span class="flow-margin-left font-weight-bold  w3-margin-top">
                            {{ $t('loading') }}
                        </span>
        <p class="zero">
          {{ $t('pleaseWait') }}
        </p>
      </h5>
    </div>
  </div>

</template>
<script>
import VisitorNameHeader from "../../common/VisitorNameHeader";

export default {
  name: "OngoingVisit",
  components: {VisitorNameHeader},
  props: ['ongoing', "callback"],
  data() {
    return {
      loaded: true
    }
  }
}

</script>