<template>
    <div class="w3-left-align">
        <i class="fa fa-inbox flow-blue-color w3-xlarge w3-margin-right"></i>
        <content-header v-bind:title="$t('addNewDomain')"></content-header>
        <div class="w3-margin-top w3-margin-bottom w3-left-align">
            <div class="w3-container zero-bottom w-90pc w3-show-inline-block flow-form-inline-input">

                <p class="flow-padding-bottom zero-top w-80pc flow-flex-col w3-left">
                    <label class="w3-left flow-blue-color flow-padding-bottom">{{ $t("name") }}</label>
                    <input class="w3-input w3-border w3-round-medium" type="text"
                           v-model="newDomain.name"
                           v-bind:placeholder="$t('name')"/>
                    <span class="w3-text-red">{{ npNameError }}</span>
                </p>

              <p class="flow-padding-bottom zero-top w-80pc flow-flex-col w3-left">
                <label class="w3-left flow-blue-color flow-padding-bottom">{{ $t("type") }}</label>
                <span class="w3-col w-100pc zero flow-margin-right sort-select">
                    <flow-select class=" w3-border w3-round-medium" type="text"
                                 :items="types"
                                 :valueKey="'name'"
                                 :valueKeyIndex="'id'"
                                 :callback="setSelectedType"
                                 :preSelectedItem = "newDomain.type" >
                    </flow-select>
                </span>
              </p>

              <div class="w3-row w-80pc">
                <p class="flow-padding-bottom zero-top w-70pc flow-flex-col w3-left">
                  <label class="w3-left flow-blue-color flow-padding-bottom">{{ $t("domain-message") }}</label>
                  <flow-select :callback="setSelectedNotificationText" :items="standardTexts"
                               :valueKey="'value'"
                               :valueKeyIndex="'key'"
                               class="w3-border w3-round-medium"
                               type="text">
                  </flow-select>
                </p>

              <p class="flow-padding-bottom zero-top w-25pc flow-flex-col w3-right">
                <label class="w3-left flow-blue-color flow-padding-bottom">{{ $t("closing-message-threshold") }}</label>
                <input class="w3-input w3-border w3-round-medium" type="number"
                       v-model="newDomain.closingTimeMinutes"
                       v-bind:placeholder="$t('minutes')"/>
              </p>

                <div class="w3-row">
        <span class="w3-text-red qpp-field-error-message"
              v-if="showMinutesError">
            {{ minutesError }}</span>
                  <br v-if="showMinutesError">


                </div>
              </div>
            </div>


            <div class="w3-container zero-bottom zero-top">
                <button class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium w3-margin-top w3-margin-right" @click.prevent="addNewDomain">
                    <i class="fa fa-save flow-green-active-color">
                        <span><b class="flow-margin-left">{{$t('save')}}</b></span>
                    </i>
                </button>

                <button class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium w3-margin-top" @click.prevent="onClose">
                    <i class="fa fa-arrow-left w3-text-red">
                        <span><b class="flow-margin-left">{{$t('back')}}</b></span>
                    </i>
                </button>
            </div>

        </div>
    </div>

</template>

<script>
    import ContentHeader from "../../common/ContentHeader";
    import FlowSelect from "@/components/common/FlowSelect";

    export default {
        name: "NewDomain",
        components: {ContentHeader, FlowSelect},
        emits: ['addedNewDomain'],
        props: ['onClose','types','standardTexts'],
        data() {
            return {
              newDomain: {},
              npNameError: undefined,
              npMinutesError: undefined,
              showMinutesError: false,
              minutesError:""
            }
        },
      mounted() {
      },
      methods: {
          checkClosingTimeMessageAndMinutes(){
            const app = this;
            app.showMinutesError = false;
            app.minutesError = "";
            let minutes = app.newDomain.closingTimeMinutes;
            let textKey = app.newDomain.notificationTextKey;

            if (minutes && ((minutes > 180|| minutes < 0))) {
              app.showMinutesError = true;
              app.minutesError = app.$t('INVALID_MINUTES');
            } else if(minutes && minutes>0 && !textKey){
              app.showMinutesError = true;
              app.minutesError = app.$t("INVALID_NOTIFICATION_TEXT");
            } else if (textKey && (!minutes || minutes===0)){
              app.showMinutesError = true;
              app.minutesError = app.$t("INVALID_CLOSING_TIME_MINUTES");
            }
          },
            addNewDomain() {
                this.npNameError = undefined;
                this.npMinutesError = undefined;

                if (!this.newDomain.name || !this.newDomain.name.trim()) {
                    this.npNameError = this.$t('INVALID_NAME');
                }

                this.checkClosingTimeMessageAndMinutes();

                if (this.npNameError || this.showMinutesError) return;
                const app = this;
                app.$restClient.domains.add(this.newDomain).then(response => {
                  // eslint-disable-next-line no-unused-vars
                  let data = response.data;
                  app.$notify.notifySuccess({title: this.$t("success"), message: this.$t('added-success')});
                  this.$emit("addedNewDomain", data);
                  app.onClose();
                }).catch(err => {
                  if(err && err.response.status === 400)
                    app.$notify.notifyError({title: this.$t("failed"), message: this.$t('domain-name-unique')});
                  else
                    app.$notify.notifyError({title: this.$t("failed")});
                });

            },

          setSelectedType(option) {
              this.newDomain.type = option;
          },
        setSelectedNotificationText(item){
          const app = this;
          if(item) {
            app.newDomain.notificationText = item;
            app.newDomain.notificationTextKey = item.key;
          }
        }
        }
    }
</script>