const errorHandler = {
    handleError(ex, notify) {
        console.log(notify);

        var message = null;
        var statusCode = null;
        var restError = null;
        if (ex.response) {
            statusCode = ex.response.status;

            restError = {
                restStatus: statusCode,
                restCodes: []
            };
            if (ex.response.data) {
                var res = ex.response.data;
                if (res.restCodes) {
                    var msg = [];
                    restError.restCodes = res.restCodes;

                    for (var i in res.restCodes) {
                        msg.push(res.restCodes[i]);
                    }
                    message = msg.join(", ");
                }
            } else if (ex.response.status) {
                message = "failedWithHttpCode", { 'status': ex.response.status };
            }
        } else if (ex.request) {
            statusCode = ex.request.status;
            message = "failedWithHttpCode", { 'status': ex.request.status + ", " + ex.request.statusText };
        } else {
            message = ex.message;
        }

        notify.notifyError({
            title: null == statusCode ? ("requestFailed") : ( statusCode + " Request Failed" ),
            message: message
        });

        return restError ? Promise.reject(restError) : Promise.reject(ex);
    }
}

export default {
    ...errorHandler
}