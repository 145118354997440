import {createApp} from 'vue';
import App from './App.vue'
import router from './router'
import restClient from './plugins/restClient/restClient.js';
import store from './store/index.js';
import notify from './plugins/notify/notify.js';
import helper from './plugins/helper.js';
import i18n from './plugins/translations/translating.js'
import errorHandler from "@/plugins/restClient/errorHandler";
import apiConfig from "./apiConfig";

const app = createApp(App);
let config = loadConfig();
app.use(router);
app.use(store);
app.use(i18n);

app.use(notify);
app.use(helper);
app.use(restClient, {
    responseInterceptor: function (response) {
        return response;
    }, function(error) {
        return errorHandler.handleError(error, notify);
    },
    apiConfig: config
});

app.mount('#app');

router.beforeEach(function (to, from, next) {

    if (to.name === "visit") {
        if (isNaN(parseInt(to.params.userId)))
            next({name: to.params.userId, params: {siteId: to.params.siteId}});
    }
    if (to.name !== "logout" && to.name !== "waitingRoomScreen" ) {
        let verify = app.config.globalProperties.$restClient.authentication.verify();

        verify.then(function () {
            let currentUser = app.config.globalProperties.$restClient.authentication.whoAmI();
            currentUser.then(function (res) {
                store.commit('users/setUserName', res.data.username);
                store.commit('users/setUserId', res.data.id);
                store.commit('users/setUserRole', res.data.role);
                store.commit('users/setUserDomainTypes', res.data.domainTypes);
            })


            if (to.path === "/") {
                let promise = app.config.globalProperties.$restClient.sites.all();
                let site;

                promise.then(function (res) {
                    let data = res.data;
                    if (data.length > 0) {
                        const res = data.map(item => {
                            return {id: item.id, name: item.name,address: item.fullAddress}
                        });
                        store.commit('sites/setSites', res);

                        // get site from localstorage
                        const storeSiteId = localStorage.getItem('currentSite')
                        let currentSite;

                        if (storeSiteId) {
                            let obj = res.find(i => i.id === parseInt(storeSiteId));
                            if (obj)
                                currentSite = obj;
                            else
                                currentSite = res[0]
                        } else {
                            currentSite = res[0]
                        }

                        site = currentSite;
                        store.commit('sites/setCurrentSite', {id: site.id, name: site.name,address: site.address});
                        next({name: "dashboard", params: {siteId: site.id}});
                    } else {
                        console.log("No sites found")
                    }
                }).catch(function (er) {
                    console.log(er)
                });
            } else {
                validateAccess(to, next)
            }
        }).catch(function (er) {
            let response = er.response;
            if (response && response.status === 401) {
                app.config.globalProperties.$restClient.authentication.login();
            }
        });

    } else {
        if(to.name === "logout") {
            console.log("log out");
            localStorage.clear();
        }
        next();
    }
});

function validateAccess(to, next) {
    let currentUserRole = store.getters["users/getCurrentUser"].role
    let metaRole = "to.meta." + currentUserRole;

    if (!eval(metaRole)) {
        next("/problem?reason=access");
    } else {
        next();
    }
}

function loadConfig() {
    let env = process.env.NODE_ENV;
    let envConfig = apiConfig[env];
    if (!envConfig) {
        console.log("Unknown environment: " + envConfig)
        window.location.href = "/error.html"
    }

    let current_host = window.location.host;
    let config = envConfig[current_host];
    if (!config) {
        console.log("Unsupported host: " + current_host)
        window.location.href = "/error.html"
    }
    app.config.globalProperties.$WRImagesPath = config.waitingRoomImagesPath;
    document.documentElement.style.setProperty("--flow-main-color", config.colorScheme);

    return config;
}